import styled from "styled-components";

const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #777;
  animation: rotate 1s linear infinite;

  margin-left: 1rem;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export function SimpleLoading() {
  return <LoadingSpinner />;
}
