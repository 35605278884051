import React, { useState, useEffect } from "react";

// Interfaces
interface IGlobalContext {
  openFormModal: boolean;
  setOpenFormModal: (value: boolean) => void;
  openParcesModal: boolean;
  setOpenParcesModal: (value: boolean) => void;
  openStoreModal: boolean;
  setOpenStoreModal: (value: boolean) => void;

  cookieAlert: boolean;
  setCookieAlert: (value: boolean) => void;

  alreadyCookieAlert: (option: string) => void;
  isMobile: boolean;
}

// Valores default
const DEFAULT_VALUE = {
  openFormModal: false,
  setOpenFormModal: (openStoreModal: boolean) => {},

  openParcesModal: false,
  setOpenParcesModal: (openStoreModal: boolean) => {},

  openStoreModal: false,
  setOpenStoreModal: (openStoreModal: boolean) => {},

  cookieAlert: true,
  setCookieAlert: (cookieAlert: boolean) => {},

  alreadyCookieAlert: (option: string) => {},
  isMobile: false,
};

export const GlobalContext = React.createContext<IGlobalContext>(DEFAULT_VALUE);

export const GlobalProvider: React.FC = ({ children }) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openParcesModal, setOpenParcesModal] = useState(false);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [cookieAlert, setCookieAlert] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Localstorage para cookies
  const checkCookies = () => {
    if (localStorage.getItem("cookieAlert")) {
      setCookieAlert(false);
    }
  };

  const alreadyCookieAlert = (option: string) => {
    localStorage.setItem("cookieAlert", option);
  };

  useEffect(() => {
    checkCookies();
  }, []);

  useEffect(() => {
    const checkIfMobile = () => {
      const mediaQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mediaQuery.matches);
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        openFormModal,
        setOpenFormModal,
        openParcesModal,
        setOpenParcesModal,
        openStoreModal,
        setOpenStoreModal,
        cookieAlert,
        setCookieAlert,
        alreadyCookieAlert,
        isMobile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => React.useContext(GlobalContext);
