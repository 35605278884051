import { useGlobal } from "providers/Global";

// Styles
import { CookieAlertStyle } from "./styles";
import { Container } from "styles/Global";

const CookieAlert: React.FC = () => {
  const { cookieAlert, setCookieAlert, alreadyCookieAlert } = useGlobal();

  return (
    <CookieAlertStyle className={cookieAlert ? "cookie-alert--active" : ""}>
      <Container className="cookie-alert__container">
        <Container className="cookie__container">
          <div className="cookie__text">
            <div className="cookie__title">
              <h3>Aviso de Cookies</h3>
            </div>

            <div className="cookie__description">
              <p>
                O AQUABIT utiliza os cookies do seu navegador para proporcionar a melhor experiência em nosso site.
                Conheça nossa{" "}
                <a href="https://aquabit.com.br/politica-de-cookies" target="_blank" rel="noreferrer">
                  Política de Cookies.
                </a>
              </p>
            </div>
          </div>

          <div className="cookie__buttons">
            {/* <button
              onClick={() => {
                setCookieAlert(false);
                alreadyCookieAlert("definições");
              }}
              className="cookie__button--personalizado"
            >
              Definições de cookies
            </button> */}

            {/* <button
              onClick={() => {
                setCookieAlert(false);
                alreadyCookieAlert("false");
              }}
            >
              Rejeitar todos
            </button> */}

            <button
              onClick={() => {
                setCookieAlert(false);
                alreadyCookieAlert("true");
              }}
              className="cookie__button--personalizado"
            >
              Sim, concordo
            </button>
          </div>
        </Container>
      </Container>
    </CookieAlertStyle>
  );
};

export default CookieAlert;
