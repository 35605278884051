import { FormEvent, useState, useEffect } from "react";
import Input from "react-phone-number-input/input";
import { api } from "services/API";
import { useGlobal } from "providers/Global";

// Toast envio do email
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// Styles
import {
  CheckBoxLabel,
  CheckboxGroupContainer,
  FormStyle,
  QuestionLabel,
} from "./styles";

// Icons
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SimpleLoading } from "components/Loading/SimpleLoading";

interface Props {
  isModal?: boolean;
}

const Form2: React.FC<Props> = ({ isModal = false }) => {
  const { setOpenFormModal } = useGlobal();

  const [enviando, setEnviando] = useState(false);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [peixeCamarao, setPeixeCamarao] = useState("");
  const [email, setEmail] = useState("");

  const [mensagem, setMensagem] = useState<string>();

  const pathname = window.location.pathname;
  useEffect(() => {
    setMensagem(pathname.replaceAll("/", ""));
  }, [pathname]);

  const enviarEmail = async (e: FormEvent) => {
    e.preventDefault();
    if (!(nome && telefone && peixeCamarao))
      toast.warning("Por favor, preencha todos os campos!");
    if (telefone.length < 14)
      toast.warning("Por favor, insira um número com 11 dígitos!");
    if (!email.includes("@"))
      toast.warning("Por favor, insira um email válido!");
    try {
      let dados: Record<string, string> = {
        nome: nome,
        telefone: telefone,
        email: email,
        tipoPeixe: peixeCamarao,
      };
      if (pathname === "/controleproducao" && mensagem) {
        dados["mensagem"] = mensagem;
      }
      setEnviando(true);
      await api.post("v1/contato/", {
        nome: nome,
        telefone: telefone,
        email: email,
        tipoPeixe: peixeCamarao,
        mensagem,
      });
      // Reset dos campos
      setNome("");
      setTelefone("");
      setPeixeCamarao("");
      setEmail("");

      // Fechar modal
      setOpenFormModal(false);
      setEnviando(false);
      toast.success("Solicitação enviada com sucesso");
    } catch (e) {
      toast.warning(
        "Não foi possível enviar a solicitação. Por favor, tente novamente"
      );
    }
  };

  return (
    <>
      <FormStyle onSubmit={(e) => enviarEmail(e)}>
        <input
          type="text"
          placeholder="Nome"
          value={nome}
          onChange={(e: any) => setNome(e.target.value)}
        />

        <Input
          placeholder="WhatsApp"
          defaultCountry="BR"
          value={telefone}
          onChange={(e: any) => setTelefone(e)}
        />
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />

        {/* <select name="select-peixe-camarao" defaultValue={""} onChange={(e: any) => setPeixeCamarao(e.target.value)}>
          <option value="" disabled hidden>
            Produtor de Peixe ou Camarão?
          </option>
          <option value="peixe">Peixe</option>
          <option value="camarao">Camarão</option>
        </select> */}
        <div>
          <QuestionLabel isWhite={isModal}>
            Produtor de Peixe ou Camarão?
          </QuestionLabel>
          <CheckboxGroupContainer>
            <CheckBoxLabel isWhite={isModal}>
              <input
                type="checkbox"
                checked={peixeCamarao.includes("peixe")}
                onChange={() => setPeixeCamarao("peixe")}
              />
              Peixe
            </CheckBoxLabel>
            <CheckBoxLabel isWhite={isModal}>
              <input
                type="checkbox"
                checked={peixeCamarao.includes("camarao")}
                onChange={() => setPeixeCamarao("camarao")}
              />
              Camarão
            </CheckBoxLabel>
          </CheckboxGroupContainer>
        </div>

        <div className="form__button">
          <button
            type="submit"
            disabled={enviando}
            style={{ cursor: enviando ? "default" : "pointer" }}
          >
            {enviando ? "ENVIANDO..." : "ENVIAR AGORA!"}
            {enviando ? <SimpleLoading /> : <MdOutlineKeyboardArrowRight />}
          </button>
        </div>
      </FormStyle>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default Form2;
